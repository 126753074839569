import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import UnorderedList from "~/components/List/Unordered";
import OrderedList from "~/components/List/Ordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={10}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 10</H2>

          <p>Your teacher may ask you to consider one of the following scenarios. If so, work with 1-2 other students to discuss and answer the questions.</p>
          <p>Record your answers in your science notebook so you can be prepared for a class discussion.</p>

          <OrderedList>
            <li className="list-alpha">Suppose you hear a report on an evening news program that begins, "Researchers published findings from a study showing that a new treatment can reduce complications of heart disease."</li>

            <UnorderedList>
              <li>Do you think people should get this new treatment immediately?</li>
              <li>What questions would you want to ask about the study before making a decision about the information?</li>
            </UnorderedList>

            <li className="list-alpha">Suppose you see a commercial on TV that says the product has been clinically tested. Do you think this means the same thing as going through a clinical trial? </li>
            <li className="list-alpha">Some social media sites use blue checkmarks to verify accounts. What does it mean to be verified? What does it tell you (or not tell you) about the site and the information it contains?</li>
          </OrderedList>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
